import styled from "styled-components";

const AboutWrapper = styled.div`
  .about-us-page {
    width: 100%;
  }

  p.react-reveal.subhead {
    color: #efae4c;
    margin-bottom: 0;
  }
  img.areaImg {
    border-radius: 10px;
  }
  .myContainer {
    width: 100%;
  }
  .aboutbac {
    background: #232122;
    background-image: url(/images/info-list-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 70px;
  }
  .aboutbac {
    .row img.item-image {
      width: 50px;
      margin-bottom: 20px;
    }
    h4 {
      font-size: 20px;
    }

    .row.first .col-md-3 {
      padding: 85px 15px !important;
    }

    .row.first .col-md-3:first-child {
      border-right: 1px solid #404040;
    }

    .row.first .col-md-3:nth-child(2) {
      border-right: 1px solid #404040;
    }

    .row {
      padding-left: 12px;
      padding-right: 12px;
    }
    .col-md-3 {
      padding: 70px 15px;
      text-align: center;
    }
    h2,
    h4 {
      color: #ffffff;
      text-align: center;
    }
    .row.second {
      .col-md-3:first-child {
        background: #000000;
      }

      .col-md-3:nth-child(2) {
        background: #e18b2a;
      }

      .col-md-3:nth-child(3) {
        background: #c8aeab;
      }
    }
  }

  .about-us-page .section-container {
    width: 100%;
    max-width: 1280px;
    z-index: 1;
    margin: auto;
    padding: 20px;
  }

  .about-us-page section {
    display: inline-flex;
    width: 100%;
  }

  .about-us-page .title-bar {
    background-image: url(/about.png);
    text-align: center;
    height: 460px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
  }
  .about-us-page .title-bar-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.46);
    width: 100%;
    height: 460px;
    z-index: 0;
  }

  .about-us-page .title-bar-heading {
    font-size: 28px;
    font-weight: 500;
    line-height: 46px;
    margin: 0;
  }
  .about-us-page .title-bar-breadcrumb {
    margin: 0;
    margin-top: 10px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  }

  .about-us-page .title-bar-separator {
    position: relative;
    bottom: 60px;
  }

  .about-us-page .title-bar-separator img.separator-image {
    width: 100%;
  }

  // .about-us-page .content-grid {
  //   display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  //   gap: 30px;
  // }

  // .about-us-page .full-width {
  //   width: 100%;
  // }
  // .about-us-page .colored-sub-title {
  //   font-size: 20px;
  //   line-height: 24px;
  //   color: #a8600e;
  //   font-weight: 400;
  //   margin: 0;
  //   margin-bottom: 10px;
  // }
  // .about-us-page .title {
  //   font-family: Mulish;
  //   font-size: 28px;
  //   font-weight: 600;
  //   line-height: 50px;
  //   letter-spacing: 0em;
  //   text-align: left;
  //   margin: 0;
  // }

  // .about-section-two {
  //   padding: 60px 0;
  // }
  // .about-section-two .info-list-bg {
  //   background: #232122;
  // }
  // .about-section-two .info-list {
  //   background-image: url(/images/info-list-bg.png);
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center;
  // }
  // .about-section-two .info-list {
  //   display: grid;
  //   grid-template-columns: repeat(3, 1fr);
  //   gap: 0;
  //   align-items: center;
  //   justify-items: center;
  //   justify-content: center;
  //   align-content: center;
  //   padding-right: 25%;
  // }
  // .about-section-two .info-list .list-item {
  //   width: 100%;
  //   text-align: center;
  //   height: 325px;
  //   display: flex;
  //   flex-direction: column;
  //   align-content: center;
  //   align-items: center;
  //   justify-content: center;
  // }
  // .about-section-two .info-list .list-item .item-title {
  //   font-size: 66px;
  //   line-height: 77px;
  //   color: #fff;
  //   font-weight: 600;
  //   margin: 0;
  // }
  // .about-section-two .info-list .list-item .item-subtitle {
  //   font-size: 24px;
  //   line-height: 28px;
  //   font-weight: 400;
  //   color: #fff;
  //   margin: 0;
  // }
  // .about-section-two .info-list .list-item .item-image {
  //   width: 75px;
  //   margin-bottom: 10px;
  // }
  // .about-section-two .info-list .list-item-2 {
  //   border-left: 1px solid #474747;
  //   border-right: 1px solid #474747;
  // }
  // .about-section-two .info-list .list-item-4 {
  //   background: #0b0b0b;
  // }
  // .about-section-two .info-list .list-item-5 {
  //   background: #e18b2a;
  // }
  // .about-section-two .info-list .list-item-6 {
  //   background: #c8aeab;
  // }

  // .about-section-three {
  //   padding-bottom: 60px;
  // }
  // .about-section-three .area-title {
  //   font-size: 28px;
  //   line-height: 32px;
  //   font-weight: 700;
  //   margin: 0;
  // }
  // .about-section-three .area-text p {
  //   font-size: 15px;
  //   font-weight: 400;
  //   line-height: 26px;
  //   letter-spacing: 0em;
  //   text-align: left;
  //   color: #6b6b6b;
  // }
  // .about-section-three .area-2 {
  //   margin-top: 50px;
  // }
  // .about-section-three .section-gray-bg {
  //   background: linear-gradient(
  //     to right,
  //     white 25%,
  //     #f6f6f6 25%,
  //     #f6f6f6 100%,
  //     white 75%
  //   );
  //   padding: 60px 0;
  // }
  // .about-section-three .content-section {
  //   padding-right: 30px;
  // }

  @media only screen and (max-device-width: 1024px) {
    .about-us-page .title-bar-separator {
      position: relative;
      bottom: 48px !important;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .about-section-two {
      padding: 20px 0 !important;
    }
    .about-section-two .info-list .list-item .item-subtitle {
      font-size: 20px !important;
      line-height: 24px !important;
    }
    .about-section-two .info-list .list-item .item-title {
      font-size: 50px !important;
      line-height: 60px !important;
    }
    .about-section-two .info-list .list-item {
      height: 250px !important;
    }
  }

  @media only screen and (max-device-width: 768px) {
    .row.first .col-md-3:first-child {
      border-bottom: 1px solid #404040;
    }

    .row.first .col-md-3:nth-child(2) {
      border-bottom: 1px solid #404040;
    }

    .row.first .col-md-3:last-child, .row.second .col-md-3:last-child {
      display: none;
    }

    img.full-width.areaImg {
      margin-bottom: 30px;
  }

    .about-us-page .content-grid {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 30px;
    }
    .section-gray-bg {
      background: none !important;
      padding: 0 !important;
    }
  }
  @media only screen and (max-device-width: 767px) {
    .about-us-page .title-bar-separator {
      bottom: 17px !important;
    }

    .about-section-two .info-list {
      padding-right: 0 !important;
      background: none !important;
    }
  }
`;

export default AboutWrapper;

import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "common/theme/interior";
import Layout from "../containers/Home/Layout";
import Fade from "react-reveal/Fade";
import "common/assets/css/main-page.css";
import Seo from "components/seo";
import AboutWrapper from "../containers/Home/about.style";
import GoogleReviews from "containers/Home/GoogleReviews";
import CallToAction from "containers/Home/CallToAction";

const About = () => {
  const seoData = {
    title: "Radiance Hair Studio - Non Surgical Hair Replacement Expert",
    description:
      "Radiance Hair Studio: Your non-surgical hair replacement expert. Discover natural-looking solutions to hair loss with our skilled professionals.",
    keywords: ["About Radiance Hair Studio"],
  };

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} />

        <Layout>
          <AboutWrapper>
            <div className="about-us-body">
              <main className="about-us-page">
                <section className="title-bar" >
                  <div className="title-bar-overlay"></div>
                  <div className="section-container">
                    <h1 className="title-bar-heading">About Us</h1>
                    <h2
                      className="title-bar-breadcrumb"
                      style={{ color: "#ffffff" }}
                    >
                      Home / About Us
                    </h2>
                  </div>
                </section>

                <section className="title-bar-separator">
                  <img
                    className="separator-image"
                    alt=""
                    src="/images/separator.png"
                  />
                </section>

                <section>
                  <div className="myContainer">
                    <div className="row">
                      <div className="col-md-6">
                        <Fade left>
                          <p className="subhead">
                            Luxury Hair Studio where you will feel unique and
                            special
                          </p>
                          <h3 className="title">Radiance Hair Studio?</h3>
                        </Fade>
                        <p>
                          <a href="/" style={{ color: "#373737" }}>HAIR!</a> As there is a well-known fact about our looks which matter at every aspect of our lives. As we age our hair loss happens at a higher pace rather the hatching of the new ones. Some of us get it as a gift from the family others have different reasons. The very first thing affected in the process is the lack of confidence. CONFIDENCE is one word that affects our lives and we cannot live, work, enjoy life as we used too and all of this is due to one big fatty reason called hair fall. And the experience is really horrifying when someone notices it the very first time.
                        </p>
                        <img
                          className="areaImg full-width mb-4"
                          src="/images/about-image-1.jpg"
                          alt="hair wig for women"
                        />
                      </div>
                      <div className="col-md-6">
                        <img
                          className="areaImg full-width mb-4"
                          src="/images/about-image-2.jpg"
                          alt="wig shop near me"
                        />
                        <p>
                          We as in humans try to do everything it takes to restore the growth naturally, take consultations, go to different clinics and after a ton of pills and oiling your scalp for months just because someone said so, the result is just a word known as disappointment. And after investing lots of money and time, all you see is a looser in the mirror with no hope left. As they say, back to square one.This is where we come in, RADIANCE – A new name but with 20 years of experience which is a perfect reason to leave a satisfactory smile on your face. We Guarantee It.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div className="myContainer">
                    <div className="aboutbac">
                      <div className="row first">
                        <div className="col-md-3">
                          <h2>10+</h2>
                          <h4>Year of experience</h4>
                        </div>
                        <div className="col-md-3">
                          <h2>50,000+</h2>
                          <h4>Happy Customer</h4>
                        </div>
                        <div className="col-md-3">
                          <h2>03</h2>
                          <h4>Branches</h4>
                        </div>
                        <div className="col-md-3">
                          <div className="emptyspace"></div>
                        </div>
                      </div>
                      <div className="row second">
                        <div className="col-md-3">
                          <img
                            class="item-image"
                            src="/images/list-item-1.png"
                            alt="hair extension in delhi"
                          />
                          <h4>Top professional hair experts</h4>
                        </div>
                        <div className="col-md-3">
                          <img
                            class="item-image"
                            src="/images/list-item-2.png"
                            alt="hair bonding in delhi"
                          />
                          <h4>Free Consutations</h4>
                        </div>
                        <div className="col-md-3">
                          <img
                            class="item-image"
                            src="/images/list-item-3.png"
                            alt="hair wigs price"
                          />
                          <h4>Approved Procedures - 0% EMI Available</h4>
                        </div>
                        <div className="col-md-3">
                          <div className="emptyspace"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                </section>

                <section>
                  <div className="myContainer">
                    <div className="row">
                      <div className="col-md-6">
                        <img
                          className="full-width areaImg"
                          alt=""
                          src="/images/vision-mission.jpg"
                        />
                      </div>
                      <div className="col-md-6">
                        <h3>Our Vision</h3>
                        <p>
                          Our vision to make a place where everyone feels comfortable and welcome. A client is our first Priority and proving top-notch service at a very affordable price is our prime objective. The goal which we set for our self is that each and every client receives an outstanding service from the moment they walk in, get complete satisfaction to the time they walk out. We want our clients to make them comfortable just like their home and give the best service so that they would refer us to their friends, their family, and their colleagues. Our Hair experts are trained to level that they can provide the next generation of excellent hairstylists and innovate through new products and services. We empower our team to experience growth and development; professionally, personally, and financially.
                        </p>
                        {/* <p>
                          We want our clients to make them comfortable just like
                          their home and give the best service so that they
                          would refer us to their friends, their family, and
                          their colleagues. Our Hair experts are trained to
                          level that they can provide the next generation of
                          excellent hairstylists and innovate through new
                          products and services. We empower our team to
                          experience growth and development; professionally,
                          personally, and financially.
                        </p> */}
                        <h3>Our Mission</h3>
                        <p>
                          Our mission is to go above and beyond <a href="/tape-in-and-clip-in-hair-extension.php" style={{ color: "#373737"}}>expectations</a> to provide professional, high-quality services to our clients. We want to create a unique environment where men and women can enjoy a full range of Hair & beauty services at a very affordable price. Our knowledgeable and professional team strives to give the best hair replacement services by using the latest products and techniques, we ensure you'll always be in style. Radiance team always values lifelong learning, constant improvement and excellence in a warm and friendly atmosphere.
                        </p>
                      </div>
                    </div>
                  </div>

                </section>

              </main>
            </div>
          </AboutWrapper>

          <div className="aboutPageWrapOne" style={{ display: "none" }}>
            <div className="container">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-md-6 mb-4" style={{ paddingRight: "50px" }}>
                  <img
                    src="/home/radiance-hair-studio-img.jpg"
                    alt="About Radiance"
                    style={{ marginBottom: "25px" }}
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <img
                        src="/home/hair-wig.jpg"
                        alt="About Radiance wigs"
                        style={{}}
                      />
                    </div>
                    <div className="col-md-6">
                      <img
                        src="/home/hair-wig2.jpg"
                        alt="About Radiance hair"
                        style={{}}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <p className="title_top" style={{ marginTop: "10px" }}>
                    About Us
                  </p>
                  <h2 className="title">Radiance Hair Studio</h2>
                  <p className="who-we-are-sec-content">
                    At Radiance Hair Studio, our goal is to make every client
                    look at their very best. Our clients receive{" "}
                    <span className="insidePspecial">high-quality,</span>{" "}
                    one-on-one attention. Each client receives a complete
                    consultation before any procedure so he or she can decide
                    the best personal course of action. There are no extra
                    charges if additional treatments are needed.
                  </p>
                  <p className="leftBorderPara">
                    CONFIDENCE is one word that affects our lives and we cannot
                    live, work, enjoy life as we used too and all of this is due
                    to one big fatty reason called hair fall.
                  </p>
                  <p className="who-we-are-sec-content">
                    And the experience is really horrifying when someone notices
                    it the very first time. We as in humans try to do everything
                    it takes to restore the growth naturally, take
                    consultations, go to different clinics and after a ton of
                    pills and oiling your scalp for months just because someone
                    said so, the result is just a word known as disappointment.
                    And after investing lots of money and time, all you see is a
                    looser in the mirror with no hope left. As they say, back to
                    square one.This is where we come in, RADIANCE – A new name
                    but with 20 years of experience which is a perfect reason to
                    leave a satisfactory smile on your face. We Guarantee It.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* About mission vision section */}
          <section
            id="missionVision"
            className="mission-vision-section"
            style={{ display: "none" }}
          >
            <div className="">
              <div className="container">
                <div className="outer-section">
                  <div className="inner-section">
                    <div className="firstpara">
                      <h3 className="title">
                        Radiance Hair Studio Core Values
                      </h3>

                      <div className="textAlignLeft">
                        <hr className="divider2" />
                      </div>

                      <p>
                        Our Hair PATCH collection has multiple options according
                        to the shape and size out the scalp moreover we accustom
                        products according to the style that suits you as well
                        as with a perfect natural look guarantee. Our products
                        are made with 100% natural human hair which can be
                        pasted with a specifically made glue which is harmless
                        for the skin and is made for this very purpose. Our
                        motto is our client satisfaction, which can only be
                        achieved by providing the best quality hair patches as
                        they go through rigorous inspection and are sterilized
                        keeping any kind of medical issues.
                      </p>

                      <p>
                        Just like natural hair, your can swim, ride a bike,
                        sleep and feel beautiful and confident and that to with
                        an inaugural discount of 50% Discount on any of our
                        products which are being sold in the market at a
                        ridiculous high price. Book your appointment and we
                        guarantee full satisfaction and a beautiful smile on
                        your face.
                      </p>
                    </div>
                  </div>

                  <div className="inner-section mission-vision-columns">
                    <div className="column">
                      <div className="icon">
                        <img src="/icon1.png" alt="" />
                        <h3 className="title-2">Our Vision</h3>
                      </div>
                      <p>
                        Our vision is to make a place where everyone feels
                        comfortable and welcome. A client is our first priority,
                        and providing top-notch service at a very affordable
                        price is our prime objective. The goal we set for
                        ourselves is that each and every client receives
                        outstanding service from the moment they walk in, gets
                        complete satisfaction to the time they walk out. We want
                        our clients to feel comfortable just like their home and
                        give the best service so that they would refer us to
                        their friends, their family, and their colleagues. Our
                        hair experts are trained to a level that they can
                        provide the next generation of excellent hairstylists
                        and innovate through new products and services. We
                        empower our team to experience growth and development;
                        professionally, personally, and financially.
                      </p>
                    </div>
                    <div className="column">
                      <div className="icon">
                        <img src="/icon1.png" alt="" />
                        <h3 className="title-2">Our Mission</h3>
                      </div>

                      <p>
                        Our mission is to go above and beyond expectations to
                        provide professional, high-quality services to our
                        clients. We want to create a unique environment where
                        men and women can enjoy a full range of Hair & beauty
                        services at a very affordable price. Our knowledgeable
                        and professional team strives to give the best hair
                        replacement services by using the latest products and
                        techniques; we ensure you'll always be in style.
                        Radiance team always values lifelong learning, constant
                        improvement, and excellence in a warm and friendly
                        atmosphere.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <GoogleReviews />
          <CallToAction />
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default About;
